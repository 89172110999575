import { FormControlLabel, FormGroup, Grid, Switch, Typography } from "@mui/material";
import { FilteredMeasuresDto } from "api-shared";
import { TFunction } from "i18next";
import Tooltip from "../../../components/Tooltip";
import ActionItemDialog from "../../../components/dialogues/ActionItemDialog";
import Select from "../../../components/input/select/Select";
import { Option } from "../../../components/input/select/types";
import LoadingAnimation from "../../../components/loading/LoadingAnimation";
import { Language, languages, translationKeys } from "../../../translations/main-translations";

interface CSVExportDialogContentProps {
    open: boolean;
    measures?: FilteredMeasuresDto;
    export: () => void;
    translate: TFunction;
    closeDialog: () => void;
    dataAvailable: boolean;
    toggleEffectCategories: boolean;
    handleChangeEffectCategories: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleUnwindBlank: boolean;
    handleChangeUnwindBlank: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleExcel: boolean;
    handleChangeExcel: (event: React.ChangeEvent<HTMLInputElement>) => void;
    toggleLinkColumn: boolean;
    handleChangeLinkColumn: (event: React.ChangeEvent<HTMLInputElement>) => void;
    isFiscalYearScope: boolean;
    splitCurrencyFieldsIntoMonthlyValues: boolean;
    handleChangeSplitCurrencyFieldsIntoMonthlyValues: (event: React.ChangeEvent<HTMLInputElement>) => void;
    showValuesInEffectCategoryCurrency: boolean;
    handleChangeEffectCategoryCurrency: (event: React.ChangeEvent<HTMLInputElement>) => void;
    selectedLanguage: Language;
    setSelectedLanguage: (newLanguage: Language) => void;
}

const CSVExportDialogContent = ({
    open,
    measures,
    export: startExport, // export is a keyword
    translate,
    closeDialog,
    dataAvailable,
    toggleEffectCategories,
    handleChangeEffectCategories,
    toggleUnwindBlank,
    handleChangeUnwindBlank,
    toggleExcel,
    handleChangeExcel,
    toggleLinkColumn,
    handleChangeLinkColumn,
    isFiscalYearScope,
    splitCurrencyFieldsIntoMonthlyValues,
    handleChangeSplitCurrencyFieldsIntoMonthlyValues,
    showValuesInEffectCategoryCurrency,
    handleChangeEffectCategoryCurrency,
    selectedLanguage,
    setSelectedLanguage,
}: CSVExportDialogContentProps) => {
    const languageOptions = languages.map((lang) => ({
        label: translate(lang),
        value: lang,
    }));

    const handleLanguageChange = (option: Option<Language> | null) => option != null && setSelectedLanguage(option.value);

    const selectedLanguageOption = languageOptions.find((o) => selectedLanguage.startsWith(o.value));

    return (
        <ActionItemDialog
            action={translationKeys.VDLANG_SAVE}
            item="item"
            primary={translationKeys.VDLANG_EXPORT_CSV_BUTTON}
            onPrimary={startExport}
            primaryDisabled={measures == null || measures.measures.length === 0 || !dataAvailable}
            title={translate(translationKeys.VDLANG_EXPORT_CSV)}
            translate={translate}
            open={open}
            onClose={closeDialog}
        >
            <Typography pb={2}>{translate(translationKeys.VDLANG_EXPORT_CSV_HINT)}</Typography>
            <FormGroup>
                <FormControlLabel
                    control={<Switch checked={toggleEffectCategories} onChange={handleChangeEffectCategories} />}
                    label={translate(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_CSPLIT)}
                />

                {toggleEffectCategories && (
                    <>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={showValuesInEffectCategoryCurrency}
                                    onChange={handleChangeEffectCategoryCurrency}
                                    disabled={!toggleEffectCategories}
                                />
                            }
                            label={translate(translationKeys.VDLANG_EXPORT_CURRENCY_TOGGLE)}
                        />
                        <FormControlLabel
                            control={
                                <Switch checked={toggleUnwindBlank} onChange={handleChangeUnwindBlank} disabled={!toggleEffectCategories} />
                            }
                            label={translate(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_UNWIND_BLANK)}
                        />
                    </>
                )}

                <Tooltip title={!isFiscalYearScope ? translate(translationKeys.VDLANG_EXPORT_SPLIT_CURRENCY_VALUES_TOGGLE_HINT) : ""}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={splitCurrencyFieldsIntoMonthlyValues}
                                onChange={handleChangeSplitCurrencyFieldsIntoMonthlyValues}
                            />
                        }
                        label={translate(translationKeys.VDLANG_EXPORT_SPLIT_CURRENCY_VALUES_TOGGLE)}
                        disabled={!isFiscalYearScope}
                    />
                </Tooltip>

                <FormControlLabel
                    control={<Switch checked={toggleExcel} onChange={handleChangeExcel} />}
                    label={translate(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_EXCEL)}
                />

                <FormControlLabel
                    control={<Switch checked={toggleLinkColumn} onChange={handleChangeLinkColumn} />}
                    label={translate(translationKeys.VDLANG_EXPORT_CSV_TOGGLE_LINK_COLUMN)}
                />

                <Select
                    label={translate(translationKeys.VDLANG_EXPORT_CSV_LANGUAGE)}
                    isMulti={false}
                    options={languageOptions}
                    value={selectedLanguageOption}
                    onChange={handleLanguageChange}
                    placeholder={translate(translationKeys.VDLANG_SELECT_PLEASE_SELECT)}
                    menuPortalTarget={document.body}
                />
            </FormGroup>
            {dataAvailable && (measures == null || measures.measures.length === 0) ? (
                <Grid container justifyContent="center">
                    <Typography mt={2}>{translate(translationKeys.VDLANG_NO_PROCESSES)}</Typography>
                </Grid>
            ) : null}
            {!dataAvailable ? (
                <Grid container justifyContent="center">
                    <LoadingAnimation sx={{ mt: 2 }} />
                    <Typography>{translate(translationKeys.VDLANG_PREPARING_DATA)}</Typography>
                </Grid>
            ) : null}
        </ActionItemDialog>
    );
};

export default CSVExportDialogContent;
